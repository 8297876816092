:root {
  --primary: #05445e;
  --secondary: #29539b;
  ---other: #189ab4;
  ---red: #f13453;
  ---white: #ffffff;
  ---black: #383838;
  ---blackSecondary: #6b6b6b;
}

/********* Hero Section ********/
.hero_head {
  font-family: Inter;
  font-size: 2rem;
  color: var(---black);
  line-height: 4rem;
}
.Algo_hero_ts {
  font-family: poppins;
  font-weight: 600;
}
.Awesome_hero_ts {
  font-family: poppins;
  font-weight: 600;
  color: var(--secondary);
}
.hero_sub_text {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 17px;
  color: var(---blackSecondary);
  line-height: 2.2rem;
}
.btn_join_now {
  background-color: var(--primary);
  font-family: Rubik;
  font-size: 1.1rem;
  color: var(---white);
}
.btn_join_now:hover {
  background-color: #086891;
  font-family: Rubik;
  font-size: 1.1rem;
  color: var(---white);
}
.Watch_btn {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary);
  text-decoration: none;
}
.highlight {
  display: inline-block;
  height: inherit;
  background-image: url("../../../public/img/Home-Page/watch-video-underline.svg");
  background-position: bottom;
  background-repeat: no-repeat;
}

.hero_right_bg {
  background-image: url("../../../public/img/Home-Page/hero-bg2.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

/******** statistics ********/
.statistics_bg {
  background-color: #f6f8fc;
}
.stats_ts {
  font-family: Rubik;
  color: var(--primary);
  font-size: 1.6rem;
  font-weight: 500;
}
.border_right {
  border-left: 5px solid rgb(214, 214, 214);
}

.sub_text {
  font-family: poppins;
  font-size: 1rem;
  font-weight: 500;
  color: var(---blackSecondary);
}

.solve_prob_head_ts {
  font-family: Mulish;
  font-weight: 400;
  color: var(---black);
  font-size: 2.2em;
}

.solve_prob_span {
  font-weight: 800;
  color: var(--primary);
}

.problems_card_mod {
  border-top: 5px solid #067d5c !important;
  min-height: 400px;
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.inner_card_mod {
  background-color: #f5f6f6;
  border-radius: 30px;
  border: 0px;
  height: 200px;
}
.card_head {
  font-family: Rubik;
  font-size: 1.3rem;
  color: var(--secondary) !important;
  font-weight: 500;
}

.card_text {
  font-family: DM Sans;
  font-weight: 500;
  color: var(---blackSecondary);
  font-size: 1rem;
}

.card_quote_mod {
  background-color: #f6f9f2;
  border: 0px;
  border-radius: 20px;
  background-image: url("../../../public/img/Home-Page/quotes.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.quote_ts {
  font-family: Mulish;
  font-weight: 700;
  color: var(--secondary);
  font-size: 1.9rem;
}

.inner_quote_ts {
  font-family: Caveat;
  font-weight: 500 !important;
}

.quote_sub_text {
  font-family: poppins;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--secondary);
}

.sliding_text {
  font-family: Mulish;
  font-weight: 600;
  font-size: 2.1em;
  color: var(--primary);
}

.sliding_text_mod {
  color: var(---red);
}

.course_bg {
  background-color: #fff9ef;
}

.course_head {
  font-family: Mulish;
  font-weight: 700;
  color: var(--primary);
  font-size: 1.8rem;
}

.course_subhead {
  font-family: DM Sans;
  font-size: 1.1rem;
  color: var(--secondary);
  font-weight: 500;
  line-height: 30px;
}

.card_mod_features {
  border: 0px;
  background-color: #fbf8ff;
}

.card_head_features {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.6rem;
  color: var(--secondary);
}
.card_subhead_features {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--primary);
  margin-top: -10px;
}

.card_text_features {
  font-family: DM Sans;
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: 400;
}

.card_mod_features_intern {
  border: 0px;
  background-color: #fef7f7;
}

.card_mod_features_placement {
  border: 0px;
  background-color: #fefcf0;
}

.features_ts_bottom {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--primary);
}

.btn_checkout {
  background-color: var(---red);
  font-family: Rubik;
  font-size: 1.1rem;
  font-weight: 400;
  color: var(---white);
}

.btn_checkout:hover {
  background-color: #d31031;
  color: var(---white);
}

.mock_bg {
  background-color: #f8f5ff;
}

.mock_promo_ts {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(---red);
}

.btn_discord {
  background-color: #8080f7;
  font-family: Rubik;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(---white);
}

.btn_discord:hover {
  background-color: #6a6afc;
  color: var(---white);
}

.discord_head_ts {
  font-family: Rubik;
  font-weight: 600;
  font-size: 1.8rem;
  color: var(--primary);
}

.dicord_inner {
  color: #8080f7;
}

.discord_text_ts {
  font-family: DM Sans;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--secondary);
  line-height: 35px;
}

.form_bg{
  background-color: #05435e0e;
}


@media (max-width: 992px) {

  .hero_right_bg {
    background-image: none;
  }
  .hide_sm {
    display: none !important;
  }
  .border_right {
    border-left: none;
  }
}

@media (min-width: 992px) {
  .hide_lg {
    display: none !important;
  }
}
