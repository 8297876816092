:root {
  --primary: #05445e;
  --secondary: #29539b;
  ---other: #189ab4;
  ---red: #f13453;
  ---white: #ffffff;
  ---black: #383838;
  ---blackSecondary: #6b6b6b;
}

.hero_bg {
  background-image: url("../../../public/img/Premium-Page/premium-az-hero-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_head_ts {
  font-family: Mulish;
  font-weight: 800;
  color: var(---white);
  font-size: 3.6rem;
}

.head_underline {
  display: inline-block;
  height: inherit;
  background-image: url("../../../public/img/Premium-Page/premium-underline.svg");
  background-position: bottom;
  background-repeat: no-repeat;
}

.hero_subhead_ts {
  font-family: Mulish;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(---white);
}

.btn_join_now {
  background-color: var(---white);
  font-family: Rubik;
  font-size: 1.2rem;
  color: var(--primary);
  font-weight: 500;
}

.btn_join_now:hover {
  background-color: rgb(236, 246, 255);
}

.btn_cirriculum {
  border: 2px solid var(---white);
  font-family: Rubik;
  font-size: 1.2rem;
  color: var(---white);
  font-weight: 500;
}

.btn_cirriculum:hover {
  background-color: aliceblue;
  color: var(--primary);
  font-weight: 500;
}

.dream_head_ts {
  font-family: Mulish;
  font-size: 2em;
  color: var(--primary);
  font-weight: 700;
}

.dream_underline {
  display: inline-block;
  height: 60px;
  background-image: url("../../../public/img/Premium-Page/orange-underline.svg");
  background-position: bottom;
  background-repeat: no-repeat;
}

.dream_ts {
  font-family: DM Sans;
  font-weight: 500;
  color: var(--primary);
  font-size: 1.4rem;
}

.dreams_bottom_ts {
  font-family: Rubik;
  font-weight: 500;
  color: var(--primary);
  font-size: 1.4rem;
}

.dream-link-ts {
  text-decoration: none !important;
  color: rgb(0, 126, 245);
}

.instructor_head_ts {
  font-family: Mulish;
  font-weight: 600;
  color: var(--primary);
  font-size: 2.1rem;
}

.instructor_underline {
  display: inline-block;
  height: 60px;
  background-image: url("../../../public/img/Premium-Page/instructor-underline.svg");
  background-position: bottom;
  background-repeat: no-repeat;
}

.instructor_card_mod {
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  /* max-height: 21.9rem; */
  /* min-height: 21rem; */
}

/* .mentor_img {
  max-width: 12rem;
} */

@media (max-width: 576px) {
  .instructor_card_mod {
    max-height: 100rem;
  }
}

@media (min-width: 576.1px) and (max-width: 768px) {
  .instructor_card_mod {
    max-height: 21.5rem;
  }
  .mentor_img {
    max-width: 13.8rem;
  }
}

@media (min-width: 768.1px) and (max-width: 992px) {
  .instructor_card_mod {
    max-height: 16rem;
  }
  .mentor_img {
    max-width: 10rem;
  }
}

@media (min-width: 992.1px) and (max-width: 1200px) {
  .instructor_card_mod {
    max-height: 15rem;
  }
  .mentor_img {
    max-width: 9rem;
  }
}

.linkedin_social_media {
  color: #0077b5;
  font-size: 26px;
}

.youtube_social_media {
  color: #ff0000;
  font-size: 28px;
}

/* @media (min-width: 1200px) and (max-width: 1400px) {
  .instructor_card_mod {
    max-height: 19.5rem;
  }
  .mentor_img {
    max-width: 12rem;
  }
} */

.card_body_instructor {
  border-top: 10px solid #e5e5e5;
  border-bottom: 5px solid #e5e5e5;
  /* height: 23rem; */
}

.card_instructor_role {
  font-family: DM Sans;
  font-weight: 500;
  color: var(---blackSecondary);
  font-size: 1rem;
}

.card_instructor_text {
  font-family: Rubik;
  font-size: 14px;
  color: var(---black);
  line-height: 24px;
}

.instructor_name {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.4rem;
  color: #3a6c80;
}

hr.hr {
  border: 1px solid rgb(180, 180, 180);
}

.course_bg {
  background-color: #fff9ef;
}

.course_head {
  font-family: Mulish;
  font-weight: 700;
  color: var(--primary);
  font-size: 1.8rem;
}

.course_subhead {
  font-family: DM Sans;
  font-size: 1.1rem;
  color: var(--secondary);
  font-weight: 500;
  line-height: 30px;
}

.btn_join_now_progress {
  background-color: var(--primary);
  font-family: Rubik;
  font-size: 1.1rem;
  color: var(---white);
}

.btn_join_now_progress:hover {
  background-color: #086891;
  font-family: Rubik;
  font-size: 1.1rem;
  color: var(---white);
}

.sub_head_youtube {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(---blackSecondary);
}

.youtube_border {
  border-right: 2px solid rgb(224, 224, 224);
}

.linked_list_card {
  background-color: #fff9ef;
  border: 0px;
  transition: all 0.2s ease-out;
}

.linked_list_card:hover {
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 10%);
}

.prefix_sum_card {
  background-color: #f2f7ff;
  border: 0px;
  transition: all 0.2s ease-out;
}

.prefix_sum_card:hover {
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 10%);
}

.prefix_bitmask_card {
  background-color: #fff3f3;
  border: 0px;
  transition: all 0.2s ease-out;
}

.prefix_bitmask_card:hover {
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 10%);
}

.card_body_mod {
  padding: 0px;
  padding: 15px 20px;
  padding-bottom: 20px;
}

.vist_ts {
  font-family: Rubik;
  font-weight: 500;
  color: var(--secondary);
  font-size: 1rem;
  text-decoration: none;
}

.youtube_card_head {
  font-family: Mulish;
  font-weight: 700;
  color: var(--secondary);
  font-size: 1.2rem;
  margin-bottom: -2px;
}

.youtube_card_subhead {
  font-family: Mulish;
  font-weight: 600;
  color: var(--primary);
  font-size: 0.9rem;
  margin-bottom: -5px;
}

.timmer_session {
  background-image: url("../../../public/img/Premium-Page/timmer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.timmer_head {
  font-family: Mulish;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(---white);
}

.btn_timmer {
  font-family: Mulish;
  font-weight: 600;
  font-size: 1.2rem;
  color: var(---white);
  transition: all 1s ease-out !important;
  background: rgb(246, 73, 17);
  background: linear-gradient(
    90deg,
    rgba(246, 73, 17, 1) 0%,
    rgba(243, 112, 19, 1) 25%,
    rgba(239, 153, 22, 1) 100%
  );
}

.btn_timmer:hover {
  color: var(---white);
  background: rgb(239, 153, 22);
  background: linear-gradient(
    90deg,
    rgba(239, 153, 22, 1) 0%,
    rgba(243, 112, 19, 1) 25%,
    rgba(246, 73, 17, 1) 100%
  );
}

.timmer_ts {
  font-family: Roboto;
  font-weight: 700;
  color: var(---white);
  font-size: 4rem;
}

.timmer_ts_hrs {
  font-family: Mulish;
  font-weight: 700;
  color: var(---white);
  margin-top: -10px !important;
  margin-left: -30px !important;
}

/* pricing  */
.pricing-bg {
}

.price_sidehead {
  font-family: Rubik;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--secondary);
}

.detailed_video_ts {
  text-decoration: none;
  cursor: pointer;
}

/****** Testimonial  ******/
.testimonial_text {
  font-family: Rubik;
  font-weight: 400;
  color: #3b3b3b;
  line-height: 1.7;
}
.Student_name {
  font-family: Rubik;
  font-weight: 500;
  color: #095170;
  font-size: 17px;
}

.Student_Course {
  font-family: Rubik;
  font-weight: 400;
  color: #252525;
  font-size: 15px;
  margin-top: -7px;
}

.query_form_bg {
  background-color: #f1f5f6;
}

.card_shadow:hover {
  box-shadow: 0 3px 5px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
}

.sub_features_ts {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 18px;
  color: #6e6e6e;
}

.batch_head {
  font-family: Inter;
  font-weight: 600;
  font-size: 1.4rem;
  color: #29539b;
}

.btn_mod_bg {
  font-family: DM Sans;
  font-weight: 500;
  background-color: #05445e;
  color: white;
}

.btn_mod_bg:hover {
  font-family: DM Sans;
  font-weight: 500;
  background-color: #085b7e;
  color: white;
}

.batch_details_ts {
  font-family: DM Sans;
  font-weight: 500;
  color: #05445e;
}

.card_mod_batch {
  border: 2px solid #05435e83;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
}

.card_mod_batch:hover {
  transform: scale(1.02);
  border: 2px solid #05435ed8;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.select_batch {
  font-family: Mulish;
  font-weight: 700;
  color: #0a6186;
  font-size: 1.4rem;
}

.select_underline {
  border-bottom: 2px solid #00628b88;
  padding-bottom: 4px;
}

.features_ts {
  font-family: DM Sans;
  font-weight: 600;
  font-size: 1.3rem;
  color: #29539b;
}

.features_orange {
  font-family: DM Sans;
  font-weight: 600;
  color: #ee5610;
}

.features_red {
  font-family: Mulish;
  font-weight: 700;
  color: #E21134;
}

.icon_bg_blue{
  color: #05445e;
}

.icon_bg {
  color: #11a075;
}

.features_text {
  font-family: Inter;
  font-weight: 500;
  color: #29539b;
  font-size: 19px;
  line-height: 1.7;
}

.problems_card_mod {
  border-top: 5px solid #067d5c !important;
  min-height: 300px;
  max-width: 300px;
  border: 0px;
  box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .problems_card_mod {
    min-height: 350px;
  }
}

.inner_card_mod {
  background-color: #f5f6f6;
  border-radius: 50%;
  border: 0px;
  height: 200px;
  max-width: 240px;
  margin-top: -20px !important;
}

.card_head {
  font-family: Rubik;
  font-size: 1.3rem;
  color: var(--secondary) !important;
  font-weight: 500;
}

.projects_bg {
  background-color: #fff9ef;
  /* background-color: #F8F3FF; */
}

.btn_project {
  font-family: Rubik;
  font-size: 1rem;
  background-color: #0a6d97;
  color: white;
}

.btn_project:hover {
  background-color: #075577;
  color: white;
}

.prob_ts {
  font-family: DM Sans;
  font-weight: 500;
  color: #e6413e;
  font-size: 1.1rem;
}

.cirricumlum_card{
  border: 0px;
  background-color: #F2F7FF;
}

.note{
  font-family: Mulish;
  font-weight: 600;
  color: rgb(95, 95, 95);
  font-size: 14px;
}

@media (max-width: 992px) {
  .hide_sm {
    display: none;
  }
}

@media (min-width: 992px) {
  .hide_lg {
    display: none;
  }
}
