.invalid_page{
  font-family: DM Sans;
  font-weight: 700;
  font-size: 3.4rem;
  color: #124275;
}

.subtext{
  font-family: Mulish;
  font-weight: 600;
  font-size: 1.2rem;
  color: #8a8a8a;
}

.btnInvalidCourse {
  background-color: #f13453;
  color: white;
  font-family: DM Sans;
  font-weight: 500;
  padding: 7px 30px;
}

.btnInvalidCourse:hover {
  background-color: #db2946;
  color: white;
  font-family: DM Sans;
  font-weight: 500;
}
