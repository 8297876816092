.hr {
  color: #8b8b8b;
  margin-top: -5px;
}

.faq_hero{
  background-image: url("../../../public/img/Premium-Page/premium-az-hero-bg.svg");
}

.faq_hero_ts{
  font-family: Mulish;
  font-weight: 700;
  color: white;
  font-size: 2.6rem;
  letter-spacing: 2px;
}

.faq_hero_subts{
  font-family: DM Sans;
  font-size: 1.2rem;
  color: white;
  letter-spacing: 1px;
}

.side_head_ts {
  font-family: Mulish;
  font-weight: 500;
  font-size: 1.1rem;
  color: #234a8d;
  cursor: pointer;
}

.accordion_button {
  font-family: Inter;
  font-weight: 500;
  color: #303030 !important;
  font-size: 1rem;
  background-color: #EDEFF7;
  border-right: 0px !important;
}

.accordion_item{
  border: 0px !important;
}

.accordion_body{
  font-family: Mulish;
  font-weight: 500;
  line-height: 25px;
  color: #5f5f5f;
}

.faq_head{
  font-family: DM Sans;
  font-weight: 500;
  font-size: 1.2rem;
  color: #2d3035;
  padding: 10px 0;
}

.faqhead{
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.4rem;
  color: #09506e;
  letter-spacing: 1px;
}

.accordion_button:not(.collapsed) {
  background-color: #EDEFF7;
}
