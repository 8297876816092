.results_hero {
  background-image: url("../../../public/img/Premium-Page/premium-az-hero-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_text {
  font-family: Mulish;
  font-size: 2.4rem;
  font-weight: 700;
  color: white;
}

.hero_subtext {
  font-family: DM Sans;
  font-size: 1.2rem;
  color: white;
}

/************** Linkdin ***************/

.testimonials_clean {
  color: #313437;
  background-color: #05435e07;
}

.testimonials_clean p {
  color: #7d8285;
}

.testimonials_clean h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (min-width: 1200px) {
  .testimonials_clean {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (max-width: 767px) {
  .testimonials_clean h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.testimonials_clean .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.testimonials_clean .intro p {
  margin-bottom: 0;
}

.testimonials_clean .people {
  padding: 0px 0 20px;
}

.testimonials_clean .item {
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  .testimonials_clean .item {
    height: 220px;
  }
}

.testimonials_clean .item .box {
  padding: 30px;
  height: 200px;
  background-color: #fff;
  position: relative;
  border: 1px solid #05435eb4;
  box-shadow: 0 12px 12px #05435e1e;
}

.testimonials_clean .item .box:after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: -24px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-width: 12px 15px;
  border-top-color: #05445e;
}

.testimonials_clean .item .author {
  margin-top: 28px;
  padding-left: 25px;
}

.testimonials_clean .item .name {
  font-weight: bold;
  margin-bottom: 2px;
  color: inherit;
}

.testimonials_clean .item .title {
  font-size: 13px;
  color: #9da9ae;
}

.testimonials_clean .item .description {
  font-family: Rubik;
  font-size: 14px;
  margin-bottom: 0;
  color: #616161;
}

.testimonials_clean .item img {
  max-width: 40px;
  float: left;
  margin-right: 12px;
  margin-top: -5px;
}

.rounded_circle {
  box-shadow: 0 10px 10px #05435e27;

  border-radius: 100px;
  width: 35%;
  /* border: 1px solid #05445E; */
}

@media (max-width: 768px) {
  .testimonials_clean .item .box {
    height: 130px;
  }
  .box_padding {
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .box_padding {
    margin-top: 50px !important;
  }
}

@media (min-width: 992px) and (max-width: 1395px) {
  .box_padding {
    margin-top: 50px !important;
  }
}

.linkedin_icon {
  font-size: 1.4rem;
  color: #0077b7;
  text-decoration: none;
}

/************** other ***************/
.card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
  border: none !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.Card_img {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 130px;
}

section .section-title {
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-family: formular-bold;
  font-size: 34px;
}

.mentors_subhead {
  font-family: "Josefin Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #344c9f;
}

.skills {
  font-family: Poppins;
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.card_title {
  font-family: Dm Sans;
  font-weight: 500;
  font-size: 20px !important;
  color: #313131;
}

.card_text {
  font-family: Rubik;
  font-size: 1em;
  color: #636363;
}

.rounded_circle {
  width: 35%;
  border: 2px solid white;
}

@media (max-width: 576px) {
  .mentors_subhead {
    font-family: "Josefin Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #344c9f;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .Card_img {
    /* background-image: linear-gradient(-30deg, #5A585A, #090947); */
    height: 100px;
  }

  .rounded_circle {
    width: 45%;
    border: 7px solid white;
  }

  .codechef {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .Card_img {
    /* background-image: linear-gradient(-30deg, #5A585A, #090947); */
    height: 110px;
  }
}

.my_masonry_grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my_masonry_grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my_masonry_grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
