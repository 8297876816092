.checkoutBg {
  background-color: #edeff5;
}

.payment_ts {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.3rem;
  color: #05445e;
}

.paymentCard {
  border: 1px solid rgba(0, 89, 255, 0.205);
  background-color: rgba(0, 110, 255, 0.007);
  height: 12rem;
  cursor: pointer;
}

.paymentCardActive {
  border: 1px solid rgba(9, 175, 78, 0.384);
  background-color: rgba(11, 141, 54, 0.042);
  height: 12rem;
  cursor: pointer;
}

.hr {
  background-color: #8a8a8a;
  margin-top: -5px;
}

.card_inner_ts {
  font-family: DM Sans;
  font-weight: 500;
  color: #05445e;
  font-size: 17px;
}

.strike {
  text-decoration: line-through;
  text-decoration-color: rgb(179, 62, 62);
  font-family: DM Sans;
  font-weight: 500;
  color: #0c6991;
}

.amount {
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.9rem;
  color: #124275;
}

.emi {
  font-family: Dm Sans;
  font-size: 14px;
  margin-top: -10px;
  font-weight: 500;
  color: rgba(83, 83, 83, 0.788);
}

.months {
  color: rgba(68, 68, 68, 0.788);
}

/* Chrome, Safari, Edge, Opera */
.inputmod::-webkit-outer-spin-button,
.inputmod::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputmod[type="number"] {
  -moz-appearance: textfield;
}

.orders {
  font-family: Inter;
  font-weight: 500;
  color: #103d6d;
  font-size: 1.1rem;
  padding-left: 15px;
  padding-top: 18px;
}

.floatingInput_ts {
  font-family: DM Sans;
  font-weight: 400;
  color: #868686;
}

.cardHeader {
  padding: 0px;
  background-color: #fafafa;
}

.sideHead {
  font-family: DM Sans;
  font-weight: 500;
  color: #175ca7;
}

.price {
  font-family: DM Sans;
  font-weight: 500;
  color: rgb(71, 71, 71);
}

.total {
  font-family: DM Sans;
  font-weight: 500;
  color: rgb(160, 41, 25);
}

.btnBlockMod {
  background-color: #1ac788;
  color: white;
  font-family: DM Sans;
  font-weight: 500;
}

.btnBlockMod:hover {
  background-color: #1baa75;
  color: white;
  font-family: DM Sans;
  font-weight: 500;
}

/************** Radio button ***************/

@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.option_input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option_input:hover {
  background: #9faab7;
}
.option_input:checked {
  background: #124275;
}
.option_input:checked::before {
  height: 25px;
  width: 25px;
  position: absolute;
  content: "✓";
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  padding-right: 5px;
  /* padding-top: 1px; */
}
.option_input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.option_input.radio {
  border-radius: 50%;
}
.option_input.radio::after {
  border-radius: 50%;
}

.price_discount_ts {
  font-size: 24px;
  font-family: poppins;
  font-weight: 600;
  color: #275096;
}

.coupon_ts {
  font-family: DM Sans;
  font-weight: 500;
  font-size: 17px;
  color: #464646;
  margin-top: -15px;
}

.coupon_code_ts{
  font-family: DM Sans;
  font-weight: 500;
  font-size: 19px;
}

.modal_content_bg{
  background-image: url("../../../public/img/Payment/confetti-29-trans.gif");
  background-size: cover;
  background-repeat: no-repeat;
}

.btn_primary_mod{
  display: none;
}

.invalid_course{
  font-family: DM Sans;
  font-weight: 700;
  font-size: 3.4rem;
  color: #124275;
}

.course_not_found{
  font-family: DM Sans;
  font-weight: 700;
  font-size: 1.8rem;
  color: #40a070;
}

.course_look{
  font-family: DM Sans;
  font-weight: 500;
  font-size: 1.2rem;
  color: #8a8a8a;
}

.btnInvalidCourse {
  background-color: #195596;
  color: white;
  font-family: DM Sans;
  font-weight: 500;
}

.btnInvalidCourse:hover {
  background-color: #245080;
  color: white;
  font-family: DM Sans;
  font-weight: 500;
}
