.footer_color {
  background-color: #124275;
  color: white;
}

.footer i {
  font-size: 2rem;
}

.copy {
  background-color: #0b2c50;
  font-size: 1rem;
  color: white;
}

.footer_links{
    display: inline-block !important;
    text-decoration: none;
    color: white;
}

.footer_links:hover{
    text-decoration: none;
    color: rgb(226, 242, 255);
}

.icons_mod{
    color: white;
    font-size: 1.8rem;
}